<template>
    <div>
        <div id="stepPro1" v-if="countProfessionalData === 1" class="px-3 pt-12">
                <v-form ref="form" v-model="addPersonalData.datosValidPer">
                <p
                    key="DPRformation"
                    ref="DPRformation"
                    class="text-center black--text font-weight-bold subtitle-1 mb-4 mx-8"
                >
                    ¿Cuál es tu último grado de formación?
                </p>
                <v-select
                    v-model="addProfessionalData.grado_formacion"
                    label="Formación"
                    outlined
                    color="teal"
                    class="grey-label"
                    :items="studyGrade"
                    :rules="[(v) => !!v || 'Selecciona un grado de formación']"
                ></v-select>
                <p key="DPRinsti" ref="DPRinsti"
                    class="text-center black--text font-weight-bold subtitle-1 mb-4 pt-4">
                    Nombre de la institución:
                </p>
                <v-text-field
                    maxlength="40"
                    v-model.trim="addProfessionalData.institucion"
                    color="teal"
                    class="pt-2 grey-label"
                    outlined
                    label="Nombre de la institución"
                    placeholder="Nombre de la institución"
                    required
                    :rules="[(v) => !!v || 'Ingresa el Nombre de tu Institución']"
                ></v-text-field>
                <p
                    key="DPRformationstate"
                    ref="DPRformationstate"
                    class="text-center black--text font-weight-bold subtitle-1 mb-4 mt-4"
                    >
                    ¿En qué estado se encuentra tu último grado de formación?
                </p>
                    <v-select
                    v-model="addProfessionalData.estado_estudios"
                    color="teal"
                    label="Estado"
                    class="grey-label"
                    outlined
                    :items="stateStudyGrade"
                    :rules="[(f) => !!f || 'Seleccione un estado de formación']"
                    required
                    ></v-select>
            </v-form>
        </div>
        <div id="step2" v-if="countProfessionalData === 2" class="px-3 pt-12">
            <p key="InfoAditional" ref="InfoAditional"
            class="text-center black--text font-weight-bold subtitle-1">
                3. Tu experiencia laboral
            </p>
            <v-img
                src="../../../assets/profesionales.png"
                aspect-ratio="1.7"
                contain
                style="border-radius: 50%"
            ></v-img>
            <p class="grey--text text--darken-1 subtitle-1 text-center pt-4">
                Conocer tu experiencia laboral nos ayudará a poder ofrecerte propuestas
                adecuadas para ti.
            </p>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    countProfessionalData: {
      type: Number,
    },
    addProfessionalData: {
      type: Object,
      required: true,
    },
    addPersonalData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      studyGrade: ['Secundaria', 'Técnica', 'Universitaria'],
      stateStudyGrade: ['Completa', 'En curso', 'Tuve que dejarlo'],
    };
  },
};
</script>
<style lang="scss" scoped>
.v-application .title {
  line-height: 23px !important;
}

.input-google-container {
  width: 100%;
  margin-top: 0.5em;
}
.input-autocomplete {
  margin-bottom: 2em;
  border-bottom: 2px solid #bdbdbd;
  transition: border-bottom 0.5s ease, width 0.5s ease;
  width: 100%;
}

.input-autocomplete:focus {
  width: 100%;
  border-bottom: 2px solid #168d86;
  outline: none;
}
.text-marker-content {
  display: flex;
  margin-bottom: 1em;
}

.marker-image {
  width: 20px;
  height: 30px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
</style>
